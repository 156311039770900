* {
  box-sizing: border-box;
}
.bold {
  font-weight: 700;
  color: #5a5a5af2;
}

.main {
  background-color: #050801;
}
.wrapInfor {
  background-color: white;
  padding: 50px;
}
.avatar {
}
.ava {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  box-shadow: 0px 0px 4px 1px #373141ab;
}
.infor {
  text-align: left;
}
.sayHi {
  background-color: #f16a52;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0.6px;
  padding: 5px 7px;
  line-height: 1.3;
  font-size: 17px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 2px;
  position: relative;
}
.sayHi:after {
  content: "";
  width: 0;
  height: 0;
  top: 100%;
  left: 10px;
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 0 0 8px 8px;
  border-left-color: #f16a52;
  border-bottom-color: transparent;
}
.inforName p:first-child {
  font-weight: 700;
  color: #585858;
  font-size: 24px;
  margin: 0;
}
.inforName p:first-child span {
  font-weight: 300;
}
.inforName p {
  margin: 0 12px 12px 0;
}
.textInfor p {
  margin: 12px;
}
.iconInfor {
  display: inline-block;
}
.codeLang {
  padding: 0 20px;
}
