* {
  box-sizing: border-box;
}
html {
  padding: 0;
  margin: 0;
}
.cover {
  background-color: #050801;
}
.cursor {
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 100;
}
.cursor--large {
  --size: 40px;
  border: 1px solid #ff3c3c;
}
.cursor--small {
  --size: 10px;
  background: #ff3c3c;
  transform: translate(-50%, -50%);
}

.socialBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  height: auto;
  background-color: #c15b22;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}
.socialBox button {
  --size: 60px;
  border: none;
  min-width: var(--size);
  min-height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
  transition: background 200ms ease, color 200ms ease;
  cursor: pointer;
}
.socialBox:hover button {
  background: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.04);
}
.socialBox:hover button:hover {
  color: #fff;
}
.social {
  background-color: #c15b22;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.support {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  display: flex;
}
.support a {
  margin: 0 10px;
  color: #fff;
  font-size: 1.8rem;
  backface-visibility: hidden;
  transition: all 150ms ease;
}
.support a:hover {
  transform: scale(1.1);
}
