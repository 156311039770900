@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  background-color: #050801;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #050801;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  width: 100%;
}
a {
  position: relative;
  display: inline-block;
  padding: 20px 25px;
  margin: 48px 0;
  color: #03e9f4;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 2px;
  overflow: hidden;
  margin-right: 30px;
}
a:hover {
  background: #03e9f4;
  color: #050801;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 200px #03e9f4;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}
a.btn {
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  margin: 8px 0;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 2px;
  overflow: hidden;
  margin-right: 20px;
  background-color: #c15b22;
}
a:hover.btn {
  background: #03e9f4;
  color: #050801;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4,
    0 0 200px #03e9f4;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}
a:nth-child(1) {
  -webkit-filter: hue-rotate(270deg);
          filter: hue-rotate(270deg);
}
a:nth-child(2) {
  -webkit-filter: hue-rotate(110deg);
          filter: hue-rotate(110deg);
}
a span {
  position: absolute;
  display: block;
}
a span:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  -webkit-animation: animate1 1s linear infinite;
          animation: animate1 1s linear infinite;
}
@-webkit-keyframes animate1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}
@keyframes animate1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}
a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  -webkit-animation: animate2 1s linear infinite;
          animation: animate2 1s linear infinite;
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
@-webkit-keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}
@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}
a span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  -webkit-animation: animate3 1s linear infinite;
          animation: animate3 1s linear infinite;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
@-webkit-keyframes animate3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}
@keyframes animate3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  -webkit-animation: animate4 1s linear infinite;
          animation: animate4 1s linear infinite;
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}
@-webkit-keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}
@keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

.grid {
  width: 100%;
  display: block;
  padding: 0;
}

.grid.wide {
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  /* margin-left: -4px;
  margin-right: -4px; */
}

.row.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.col {
  padding-left: 4px;
  padding-right: 4px;
}

.row.no-gutters .col {
  padding-left: 0;
  padding-right: 0;
}

.c-0 {
  display: none;
}

.c-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.c-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.c-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.c-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.c-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.c-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.c-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.c-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.c-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.c-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.c-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.c-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.c-o-1 {
  margin-left: 8.33333%;
}

.c-o-2 {
  margin-left: 16.66667%;
}

.c-o-3 {
  margin-left: 25%;
}

.c-o-4 {
  margin-left: 33.33333%;
}

.c-o-5 {
  margin-left: 41.66667%;
}

.c-o-6 {
  margin-left: 50%;
}

.c-o-7 {
  margin-left: 58.33333%;
}

.c-o-8 {
  margin-left: 66.66667%;
}

.c-o-9 {
  margin-left: 75%;
}

.c-o-10 {
  margin-left: 83.33333%;
}

.c-o-11 {
  margin-left: 91.66667%;
}

/* >= Tablet */
@media (min-width: 740px) {
  .row {
    /* margin-left: -8px;
    margin-right: -8px; */
  }

  .col {
    padding-left: 8px;
    padding-right: 8px;
  }

  .m-0 {
    display: none;
  }

  .m-1,
  .m-2,
  .m-3,
  .m-4,
  .m-5,
  .m-6,
  .m-7,
  .m-8,
  .m-9,
  .m-10,
  .m-11,
  .m-12 {
    display: block;
  }

  .m-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .m-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .m-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .m-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .m-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .m-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .m-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .m-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .m-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .m-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .m-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .m-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .m-o-1 {
    margin-left: 8.33333%;
  }

  .m-o-2 {
    margin-left: 16.66667%;
  }

  .m-o-3 {
    margin-left: 25%;
  }

  .m-o-4 {
    margin-left: 33.33333%;
  }

  .m-o-5 {
    margin-left: 41.66667%;
  }

  .m-o-6 {
    margin-left: 50%;
  }

  .m-o-7 {
    margin-left: 58.33333%;
  }

  .m-o-8 {
    margin-left: 66.66667%;
  }

  .m-o-9 {
    margin-left: 75%;
  }

  .m-o-10 {
    margin-left: 83.33333%;
  }

  .m-o-11 {
    margin-left: 91.66667%;
  }
}

/* PC medium resolution > */
@media (min-width: 1113px) {
  .row {
    /* margin-left: -12px;
    margin-right: -12px; */
  }

  .row.sm-gutter {
    margin-left: -5px;
    margin-right: -5px;
  }

  .col {
    padding-left: 12px;
    padding-right: 12px;
  }

  .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px;
  }

  .l-0 {
    display: none;
  }

  .l-1,
  .l-2,
  .l-2-4,
  .l-3,
  .l-4,
  .l-5,
  .l-6,
  .l-7,
  .l-8,
  .l-9,
  .l-10,
  .l-11,
  .l-12 {
    display: block;
  }

  .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .l-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .l-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .l-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .l-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .l-o-1 {
    margin-left: 8.33333%;
  }

  .l-o-2 {
    margin-left: 16.66667%;
  }

  .l-o-3 {
    margin-left: 25%;
  }

  .l-o-4 {
    margin-left: 33.33333%;
  }

  .l-o-5 {
    margin-left: 41.66667%;
  }

  .l-o-6 {
    margin-left: 50%;
  }

  .l-o-7 {
    margin-left: 58.33333%;
  }

  .l-o-8 {
    margin-left: 66.66667%;
  }

  .l-o-9 {
    margin-left: 75%;
  }

  .l-o-10 {
    margin-left: 83.33333%;
  }

  .l-o-11 {
    margin-left: 91.66667%;
  }
}

/* Tablet - PC low resolution */
@media (min-width: 740px) and (max-width: 1023px) {
  .wide {
    width: 644px;
  }
}

/* > PC low resolution */
@media (min-width: 1024px) and (max-width: 1239px) {
  .wide {
    width: 984px;
  }

  .wide .row {
    /* margin-left: -12px;
    margin-right: -12px; */
  }

  .wide .row.sm-gutter {
    /* margin-left: -5px;
    margin-right: -5px; */
  }

  .wide .col {
    padding-left: 12px;
    padding-right: 12px;
  }

  .wide .row.sm-gutter .col {
    padding-left: 5px;
    padding-right: 5px;
  }

  .wide .l-0 {
    display: none;
  }

  .wide .l-1,
  .wide .l-2,
  .wide .l-2-4,
  .wide .l-3,
  .wide .l-4,
  .wide .l-5,
  .wide .l-6,
  .wide .l-7,
  .wide .l-8,
  .wide .l-9,
  .wide .l-10,
  .wide .l-11,
  .wide .l-12 {
    display: block;
  }

  .wide .l-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .wide .l-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .wide .l-2-4 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .wide .l-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .wide .l-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .wide .l-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .wide .l-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .wide .l-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .wide .l-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .wide .l-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .wide .l-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .wide .l-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .wide .l-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .wide .l-o-1 {
    margin-left: 8.33333%;
  }

  .wide .l-o-2 {
    margin-left: 16.66667%;
  }

  .wide .l-o-3 {
    margin-left: 25%;
  }

  .wide .l-o-4 {
    margin-left: 33.33333%;
  }

  .wide .l-o-5 {
    margin-left: 41.66667%;
  }

  .wide .l-o-6 {
    margin-left: 50%;
  }

  .wide .l-o-7 {
    margin-left: 58.33333%;
  }

  .wide .l-o-8 {
    margin-left: 66.66667%;
  }

  .wide .l-o-9 {
    margin-left: 75%;
  }

  .wide .l-o-10 {
    margin-left: 83.33333%;
  }

  .wide .l-o-11 {
    margin-left: 91.66667%;
  }
}

* {
  box-sizing: border-box;
}
.bold {
  font-weight: 700;
  color: #5a5a5af2;
}

.main {
  background-color: #050801;
}
.wrapInfor {
  background-color: white;
  padding: 50px;
}
.avatar {
}
.ava {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  box-shadow: 0px 0px 4px 1px #373141ab;
}
.infor {
  text-align: left;
}
.sayHi {
  background-color: #f16a52;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 0.6px;
  padding: 5px 7px;
  line-height: 1.3;
  font-size: 17px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 2px;
  position: relative;
}
.sayHi:after {
  content: "";
  width: 0;
  height: 0;
  top: 100%;
  left: 10px;
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 0 0 8px 8px;
  border-left-color: #f16a52;
  border-bottom-color: transparent;
}
.inforName p:first-child {
  font-weight: 700;
  color: #585858;
  font-size: 24px;
  margin: 0;
}
.inforName p:first-child span {
  font-weight: 300;
}
.inforName p {
  margin: 0 12px 12px 0;
}
.textInfor p {
  margin: 12px;
}
.iconInfor {
  display: inline-block;
}
.codeLang {
  padding: 0 20px;
}

* {
  box-sizing: border-box;
}
html {
  padding: 0;
  margin: 0;
}
.cover {
  background-color: #050801;
}
.cursor {
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 100;
}
.cursor--large {
  --size: 40px;
  border: 1px solid #ff3c3c;
}
.cursor--small {
  --size: 10px;
  background: #ff3c3c;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.socialBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  height: auto;
  background-color: #c15b22;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}
.socialBox button {
  --size: 60px;
  border: none;
  min-width: var(--size);
  min-height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
  transition: background 200ms ease, color 200ms ease;
  cursor: pointer;
}
.socialBox:hover button {
  background: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.04);
}
.socialBox:hover button:hover {
  color: #fff;
}
.social {
  background-color: #c15b22;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.support {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  display: flex;
}
.support a {
  margin: 0 10px;
  color: #fff;
  font-size: 1.8rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: all 150ms ease;
}
.support a:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.portfolioPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  background-color: #050801;
  overflow: hidden;
}

.nameShow h1 {
  position: relative;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: calc(10px + 2vw);
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-shadow: 0 0 0.15em #1da9cc;
  -webkit-user-select: none;
          user-select: none;
  white-space: nowrap;
  -webkit-filter: blur(0.007em);
          filter: blur(0.007em);
}
.nameShow h3 {
  position: relative;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: calc(5px + 1vw);

  color: #fff;
  letter-spacing: 0.02em;
  text-shadow: 0 0 0.15em #1da9cc;
  -webkit-user-select: none;
          user-select: none;
  white-space: nowrap;
  -webkit-filter: blur(0.007em);
          filter: blur(0.007em);
}

.portContainer {
  background-color: #050801;
}
/* Show card logo */
.LogoCard {
  max-width: 1200px;
  margin: auto;
  background-color: white;
}
.showProject {
  max-width: 1200px;
  margin: auto;
  background-color: white;
  padding: 0 50px 50px;
}
.showcase {
  text-align: center;
  padding-top: 60px;
  justify-content: space-around;
  padding: 50px;
}

.showcase h1 {
  font-size: 5em;
}
.stm-font {
  font-family: "Share Tech Mono", monospace;
}
.shadow {
  box-shadow: 7px 7px 7px #666;
  position: relative;
  transition: all 0.2s;
  top: 0;
}
.up-hover:hover {
  top: -5px;
  box-shadow: 15px 15px 15px #777;
}

.color-hover:hover {
  color: #c15b22;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}
.wellProject {
  padding: 19px;
  margin-bottom: 20px;
  background-color: #effdfa;
}
/* Project */
.projectInfor {
  text-align: left;
}
.btnSide {
  position: relative;
}
.btnLeft {
  text-align: end;
}

/* Spliting words */
:root {
  --fluid-type-min: 1rem;
  --fluid-type-max: 8rem;
  --fluid-type-target: 5vw;
  --color: #dbe3ea;
}
/*body */
.words {
  background: #24313c;
  color: #dbe3ea;
  color: var(--color);
  padding: 2rem;
  font-family: "Cabin Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  display: grid;
  place-items: center;
}

.words h1 > span {
  line-height: 1;
  font-size: clamp(
    1rem,
    calc(1rem + 5vw),
    8rem);
  font-size: clamp(
    var(--fluid-type-min, 1rem),
    calc(1rem + var(--fluid-type-target, 3vw)),
    var(--fluid-type-max, 1.3rem)
  );
  display: block;
}

.wordsSplit {
  --fluid-type-min: 4rem;
  --fluid-type-max: 12rem;
  --fluid-type-target: 20vw;
  font-family: acier-bat-gris, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 0.7;
  margin-left: -0.06ch;
}

.wordsSplit .char {
  cursor: zoom-in;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  transition: color 400ms ease, -webkit-transform 400ms cubic-bezier(0.4, 0, 0.7, 1.8);
  transition: transform 400ms cubic-bezier(0.4, 0, 0.7, 1.8), color 400ms ease;
  transition: transform 400ms cubic-bezier(0.4, 0, 0.7, 1.8), color 400ms ease, -webkit-transform 400ms cubic-bezier(0.4, 0, 0.7, 1.8);
  color: #dbe3ea;
  color: var(--color);
  box-shadow: none;
}

.wordsSplit .char:nth-child(3),
.wordsSplit .char:nth-child(4) {
  letter-spacing: 0.02ch;
}

.wordsSplit .char:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  transition-duration: 200ms;
  text-shadow: 0px 0px 3px black, 0px 0px 20px #dbe3ea;
  text-shadow: 0px 0px 3px black, 0px 0px 20px var(--color);
  z-index: 1;
}

.wordsSplit .char:nth-child(1):hover {
  --color: #ffd300;
}

.wordsSplit .char:nth-child(2):hover {
  --color: #de38c8;
}

.wordsSplit .char:nth-child(3):hover {
  --color: #8b5fda;
}

.wordsSplit .char:nth-child(4):hover {
  --color: #86bbd8;
}

.wordsSplit .char:nth-child(5):hover {
  --color: #d3f6db;
}

.wordsSplit .char:nth-child(6):hover {
  --color: #eb9486;
}

/* Mobile */
@media (max-width: 740px) {
  .s-full-width,
  .s-col-full {
    width: 100% !important;
    text-align: center;
  }
}

.contactContain {
  margin: 0;
  padding: 0;
  display: grid;
  place-items: center;
  background-color: white;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.contactContain h1 {
  margin-top: 4rem;
}

.contactContain form {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.contactForm {
  margin-bottom: 4rem;
}
input,
select,
textarea {
  padding: 1.4rem;
  background-color: transparent;
  border: 1px solid black;
}
.contactContain textarea {
  height: 6rem;
}
.contactForm button {
  width: 8rem;
  padding: 1.4rem;
  cursor: pointer;
  background-color: #c15b22;
  color: white;
  border: none;
}
.submitBtn {
  width: 24rem;
  place-items: center;
}

