* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.portfolioPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  background-color: #050801;
  overflow: hidden;
}

.nameShow h1 {
  position: relative;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: calc(10px + 2vw);
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-shadow: 0 0 0.15em #1da9cc;
  user-select: none;
  white-space: nowrap;
  filter: blur(0.007em);
}
.nameShow h3 {
  position: relative;
  font-family: "Montserrat", Arial, sans-serif;
  font-size: calc(5px + 1vw);

  color: #fff;
  letter-spacing: 0.02em;
  text-shadow: 0 0 0.15em #1da9cc;
  user-select: none;
  white-space: nowrap;
  filter: blur(0.007em);
}

.portContainer {
  background-color: #050801;
}
/* Show card logo */
.LogoCard {
  max-width: 1200px;
  margin: auto;
  background-color: white;
}
.showProject {
  max-width: 1200px;
  margin: auto;
  background-color: white;
  padding: 0 50px 50px;
}
.showcase {
  text-align: center;
  padding-top: 60px;
  justify-content: space-around;
  padding: 50px;
}

.showcase h1 {
  font-size: 5em;
}
.stm-font {
  font-family: "Share Tech Mono", monospace;
}
.shadow {
  box-shadow: 7px 7px 7px #666;
  position: relative;
  transition: all 0.2s;
  top: 0;
}
.up-hover:hover {
  top: -5px;
  box-shadow: 15px 15px 15px #777;
}

.color-hover:hover {
  color: #c15b22;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}
.wellProject {
  padding: 19px;
  margin-bottom: 20px;
  background-color: #effdfa;
}
/* Project */
.projectInfor {
  text-align: left;
}
.btnSide {
  position: relative;
}
.btnLeft {
  text-align: end;
}

/* Spliting words */
:root {
  --fluid-type-min: 1rem;
  --fluid-type-max: 8rem;
  --fluid-type-target: 5vw;
  --color: #dbe3ea;
}
/*body */
.words {
  background: #24313c;
  color: var(--color);
  padding: 2rem;
  font-family: "Cabin Condensed", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  display: grid;
  place-items: center;
}

.words h1 > span {
  line-height: 1;
  font-size: clamp(
    var(--fluid-type-min, 1rem),
    calc(1rem + var(--fluid-type-target, 3vw)),
    var(--fluid-type-max, 1.3rem)
  );
  display: block;
}

.wordsSplit {
  --fluid-type-min: 4rem;
  --fluid-type-max: 12rem;
  --fluid-type-target: 20vw;
  font-family: acier-bat-gris, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 0.7;
  margin-left: -0.06ch;
}

.wordsSplit .char {
  cursor: zoom-in;
  transform-origin: center bottom;
  transition: transform 400ms cubic-bezier(0.4, 0, 0.7, 1.8), color 400ms ease;
  color: var(--color);
  box-shadow: none;
}

.wordsSplit .char:nth-child(3),
.wordsSplit .char:nth-child(4) {
  letter-spacing: 0.02ch;
}

.wordsSplit .char:hover {
  transform: scale(1.4);
  transition-duration: 200ms;
  text-shadow: 0px 0px 3px black, 0px 0px 20px var(--color);
  z-index: 1;
}

.wordsSplit .char:nth-child(1):hover {
  --color: #ffd300;
}

.wordsSplit .char:nth-child(2):hover {
  --color: #de38c8;
}

.wordsSplit .char:nth-child(3):hover {
  --color: #8b5fda;
}

.wordsSplit .char:nth-child(4):hover {
  --color: #86bbd8;
}

.wordsSplit .char:nth-child(5):hover {
  --color: #d3f6db;
}

.wordsSplit .char:nth-child(6):hover {
  --color: #eb9486;
}
